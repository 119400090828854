import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Badge } from 'assets/icons/badge.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as Play } from 'assets/icons/play-video.svg';
import { ReactComponent as PlayGray } from 'assets/icons/play-gray.svg';
import { ReactComponent as Wallet } from 'assets/icons/wallet.svg';
import { ReactComponent as Category } from 'assets/icons/category.svg';
import { appContext } from 'context/appContext';
import { ROLE_FLAG, userContext } from 'context/userContext';
import useComponentVisible from 'hooks/useComponentVisible';
import Button from '../buttons/Button';
import Loader from 'components/loader/Loader';
import CourseCard from 'components/header/components/CourseCard';
import classes from './Navigation.module.scss';

const NavItem = (navItem: any) => {
  const navigate = useNavigate();
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false);

  const { item } = navItem;

  return (
    <div
      className={`${classes['nav-item']} ${
        classes[item.isStyled ? 'styled' : '']
      }`}
      ref={ref}
      onMouseEnter={() => setIsComponentVisible(true)}
      onMouseLeave={() => setIsComponentVisible(false)}
    >
      <Button
        key={item.id}
        onClick={() => navigate(item.link)}
        variant={item.variant}
        icon={item.icon}
        iconPosition="left"
        size={(item?.size as any) || 'md'}
      >
        {item.name}
      </Button>
      {isComponentVisible && !!item.hoverComponent && item.hoverComponent}
    </div>
  );
};

const Navigation = () => {
  const navigate = useNavigate();
  const { theme } = useContext(appContext);
  const { userData, isTokenExpired, lastWatchedCourse } =
    useContext(userContext);

  const primaryItems = useMemo(
    () => [
      {
        id: 0,
        name: 'Create Blog Post',
        link: '/blog/create',
        role_flag: ROLE_FLAG.MARKETING_MANAGER,
        icon: PlusIcon,
        variant: 'contrast',
        show: true,
        isStyled: true
      },
      {
        id: 1,
        name: 'Courses',
        link: '/courses',
        variant: 'link-contrast',
        role_flag: ROLE_FLAG.USER,
        icon: Badge,
        show: true,
        isStyled: false
      },
      {
        id: 2,
        name: 'Creators',
        link: `/users`,
        variant: 'link-contrast',
        role_flag: ROLE_FLAG.USER,
        icon: User,
        show: true,
        isStyled: false
      },
      {
        id: 3,
        name: 'About us',
        link: `/about-us`,
        variant: 'link-contrast',
        role_flag: ROLE_FLAG.USER,
        icon: QuestionIcon,
        show: true,
        isStyled: false
      },
      {
        id: 4,
        link: `/courses/${lastWatchedCourse?.slug}/${lastWatchedCourse?.purchased?.last_watched_video}/videos?changes=0`,
        role_flag: ROLE_FLAG.USER,
        variant: 'link-contrast',
        icon: theme === 'dark' ? PlayGray : Play,
        size: 'lg',
        isStyled: false,
        show: !!lastWatchedCourse && lastWatchedCourse?.progress < 100,
        hoverComponent: (
          <div className={classes['continue-watching__wrapper']}>
            <div className={classes['continue-watching__container']}>
              <div>Continue Watching</div>
              {!!lastWatchedCourse && (
                <div className={classes['continue-watching-card']}>
                  <CourseCard
                    course={lastWatchedCourse}
                    onClick={() => {
                      navigate(
                        `/courses/${lastWatchedCourse.slug}/${lastWatchedCourse.purchased?.last_watched_video}/videos?changes=0`
                      );
                    }}
                    hasTitleCharLimit
                    imgSize="sm"
                    noBadge
                  />
                </div>
              )}
            </div>
          </div>
        )
      },
      {
        id: 5,
        name: 'Courses',
        link: '/admin/courses',
        variant: 'link-contrast',
        role_flag: ROLE_FLAG.ADMIN,
        icon: Badge,
        show: true,
        isStyled: false
      },
      {
        id: 6,
        name: 'Categories',
        variant: 'link-contrast',
        link: '/admin/categories',
        role_flag: ROLE_FLAG.ADMIN,
        icon: Category,
        show: true,
        isStyled: false
      },
      {
        id: 7,
        name: 'Users',
        link: '/admin/users',
        variant: 'link-contrast',
        role_flag: ROLE_FLAG.ADMIN,
        icon: User,
        show: true,
        isStyled: false
      },
      {
        id: 8,
        name: 'Transactions',
        variant: 'link-contrast',
        link: '/admin/transactions',
        role_flag: ROLE_FLAG.ADMIN,
        icon: Wallet,
        show: true,
        isStyled: false
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastWatchedCourse, theme]
  );

  const renderNavigation = () => {
    // When not logged in, return user nav items
    if (isTokenExpired)
      return primaryItems
        .filter((item) => item.role_flag === ROLE_FLAG.USER && item.show)
        .map((item) => (
          <Button
            key={item.id}
            icon={item.icon}
            iconPosition="left"
            variant="link-contrast"
            onClick={() => navigate(item.link)}
          >
            {item.name}
          </Button>
        ));

    // When access token has not expired AND the
    // userData is still loading return a loader
    if (!userData.role_flag && !isTokenExpired) {
      return <Loader size="sm" />;
    }

    // userData was set, show the relative nav items
    // based on the user role_flag
    if (!!userData.role_flag) {
      return primaryItems
        .filter((item) => item.role_flag === userData?.role_flag && item.show)
        .map((item) => <NavItem key={item.id} item={item} />);
    }
  };

  return <div className={classes['nav']}>{renderNavigation()}</div>;
};

export default Navigation;
